.morris-hover{position:absolute;z-index:1000}.morris-hover.morris-default-style{border-radius:10px;padding:6px;color:#666;background:rgba(255,255,255,0.8);border:solid 2px rgba(230,230,230,0.8);font-family:sans-serif;font-size:12px;text-align:center}.morris-hover.morris-default-style .morris-hover-row-label{font-weight:bold;margin:0.25em 0}
.morris-hover.morris-default-style .morris-hover-point{white-space:nowrap;margin:0.1em 0}

#logout-Img{
    margin-top: 1em;
}
#account-Img{
    margin-top: 1em;
    margin-left: 1em;   
}

.navbar-primary
{
    background: #278c8e;
}

.navbar-brand
{
    padding: 15px 17px;
}

.navbar-brand img{
    width:100px;
}

.right-menu ul li i{
    color: #fff;
}

.right-menu {
    padding-top: 20px;
}

.nav>li>a
{
    color: #000;
}

.sidebar
{
    margin-top:70px;
}

#page-wrapper
{
    padding-top:15px;
}

#page-wrapper h1{
    margin-top:0;
}

.navbar
{
    min-height: 70px;
}
.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
  }
  
  .pagination a.active {
    background-color: dodgerblue;
    color: white;
  }
  
  .pagination a:hover:not(.active) {background-color: #ddd;}
html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}


