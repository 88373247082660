#logout-Img{
    margin-top: 1em;
}
#account-Img{
    margin-top: 1em;
    margin-left: 1em;   
}

.navbar-primary
{
    background: #278c8e;
}

.navbar-brand
{
    padding: 15px 17px;
}

.navbar-brand img{
    width:100px;
}

.right-menu ul li i{
    color: #fff;
}

.right-menu {
    padding-top: 20px;
}

.nav>li>a
{
    color: #000;
}

.sidebar
{
    margin-top:70px;
}

#page-wrapper
{
    padding-top:15px;
}

#page-wrapper h1{
    margin-top:0;
}

.navbar
{
    min-height: 70px;
}