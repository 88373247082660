.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
  }
  
  .pagination a.active {
    background-color: dodgerblue;
    color: white;
  }
  
  .pagination a:hover:not(.active) {background-color: #ddd;}